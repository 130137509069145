import React from "react"
import { Link } from "gatsby"
import GatsbyBackgroundImage from 'gatsby-background-image' 

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"


import '../main.scss'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

/** @jsx jsx */
import { css, jsx } from '@emotion/core'



// images
import aarplogo from "../img/logo-aarp-big.png"
import aarp1 from '../img/case-aarp-1.jpg'
import aarp2 from '../img/case-aarp-2.jpg'
import aarp3 from '../img/case-aarp-3.jpg'
import aarp4 from '../img/case-aarp-4.jpg'
import aarp5 from '../img/case-aarp-5.jpg'
import aarp6 from '../img/case-aarp-6.jpg'
import aarp7 from '../img/case-aarp-7.jpg'
import aarp8 from '../img/case-aarp-8.jpg'
import aarp9 from '../img/case-aarp-9.jpg'
import aarp10 from '../img/case-aarp-10.jpg'


const AarpPage = () => {
    const slickSettings = {
        dots:true,
        infinite:true,
        speed:500,
        slidesToShow:1,
        slidesToScroll:1
    }
    return (
    <Layout>
        <SEO title="AARP" />
        <div className="all">
            <div id="cases">
            <div id="aarp" className="case">
                <div className="close"><Link to="/">Go Back Home</Link></div>
                    <div className="mast shift">
                        <img src={aarplogo} alt="AARP" />
                        <h1><span>AARP</span></h1>
                        <h2>Catch helped AARP change the way people think about being 50</h2>
                    </div>
                    <div className="intro">
                        <p>Catch worked with AARP Media Sales to accomplish a critical objective: sell in the 50+ audience to a young and youth-obsessed media industry. Nielsen had recently begun measuring audiences over 50 years old, but media buyers and planners, many under 30 themselves, had an outdated and skewed sense of AARP and its media properties. It was our job to re-introduce the AARP Media brand to the industry, support the AARP sales staff to grow print and digital sales and attract top-tier brands that previously shunned AARP. And we're proud to say we've accomplished all three.</p>
                    </div>
                    <div className="work">
                        <ul>
                            <li><img src={aarp1} alt="" /></li>
                            <li><img src={aarp2} alt="" /></li>
                            <li><img src={aarp3} alt="" /></li>
                            <li><img src={aarp4} alt="" /></li>
                            <li><img src={aarp5} alt="" /></li>
                            <li><img src={aarp6} alt="" /></li>
                            <li><img src={aarp7} alt="" /></li>
                        </ul>
                    </div>
                    <div className="testmonial">
                        <p>"Catch has a strong POV yet they accommodate me and my many masters to deliver strong results. What we're trying to say to marketers is put your money where the money is."</p>
                        <span>Patricia Lippe Davis, Vice President, Marketing AARP Media Sales</span>
                    </div>
                    <div className="work">
                        <ul>
                            <li><img src={aarp8} alt="" /></li>
                            <li><img src={aarp9} alt="" /></li>
                            <li><img src={aarp10} alt="" /></li>
                        </ul>
                    </div>
                </div>{/*//#aarp*/}
            </div>
        </div>{/* #all */}
    </Layout>
)}

export default AarpPage
